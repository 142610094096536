<!-- =========================================================================================
  File Name: UserView.vue
  Description: User View page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="page-user-view">

    <vs-alert color="danger" title="User Not Found" :active.sync="user_not_found">
      <span>User record with id: {{ $route.params.userId }} not found. </span>
      <span>
        <span>Check </span><router-link :to="{name:'page-user-list'}" class="text-inherit underline">All Users</router-link>
      </span>
    </vs-alert>

    <div id="user-data" >

      <vx-card title="Account" class="mb-base">

        <!-- Avatar -->
        <div class="vx-row">

          <!-- Avatar Col -->
          <div class="vx-col" id="avatar-col">
            <div class="img-container mb-4" v-if="user_data.profileImageUrl"> 
              <img :src="user_data.profileImageUrl" class=" imgfit" />
            </div>
            <div class="img-container mb-4 mt-1" v-else>
              <img src="https://freshclinics-bucket.s3.ap-southeast-2.amazonaws.com/undefined/k3r1nm7bpf921595963499155.png" class="imgfit" />
            </div>
          </div>

          <!-- Information - Col 1 -->
          <div class="vx-col flex-1" id="account-info-col-1">
            <table>
              <tr>
                <td class="font-semibold">Name</td>
                <td>{{ user_data.fullName }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Email</td>
                <td>{{ user_data.email }}</td>
              </tr>
              <tr>
                <td class="font-semibold">User Type</td>
                <td>{{ user_data.userType == 'admin' ?'Admin':'Super Admin' }}</td>
              </tr>
            </table>
          </div>
          <!-- /Information - Col 1 -->

          <!-- Information - Col 2 -->
          <div class="vx-col flex-1" id="account-info-col-2">
            <table>
              <tr>
                <td class="font-semibold">Status</td>
                <td>{{ user_data.status }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Profile Completed</td>
                <td>
                    <vs-icon v-if="user_data.profileCreated" icon="check_circle" size="small" color="#0b55bd"></vs-icon>
                    <vs-icon v-else icon="cancel" size="small" color="#bd0b0b"></vs-icon>
                </td>
              </tr>
            </table>
          </div>
          <!-- /Information - Col 2 -->
          <div class="vx-col w-full flex" id="account-manage-buttons">
            <vs-button icon-pack="feather" icon="icon-edit" class="mr-4" :to="{name: 'EditAdmin', params: { adminId: $route.params.adminId }}">Edit</vs-button>
            <vs-button type="border" color="danger" icon-pack="feather" icon="icon-slash" @click="confirmDeleteRecord">Delete</vs-button>
          </div>

        </div>

      </vx-card>

    </div>
  </div>
</template>

<script>
import moduleUserManagement from '@/store/user-management/moduleUserManagement.js'
import { mapActions } from 'vuex';
import moment from 'moment'
export default {
  data() {
    return {
      user_data: {},
      user_not_found: false,
    }
  },
  computed: {
    
  },
  methods: {
    ...mapActions('tutor', ['fetchTutorById','deleteUserById']),
    moment(date){
      return moment(date)
    },
    getTutorDetails() {
      let self = this;
      this.fetchTutorById(self.$route.params.adminId).then(async res => {
        self.user_data = res.data.data;
        await self.orderAvailabilities()
        console.log(self.user_data)
      })
    },
    orderAvailabilities(){
      this.user_data.availabilities.forEach(function(availability){
        availability.morning = []
        availability.afternoon = []
        availability.availableTimes.forEach(function(availableTime){
            if(parseInt(availableTime.startTime.substring(0,2)) < 12){
              availability.morning.push(availableTime.startTime + " - " + availableTime.endTime)
            }
            else{
              availability.afternoon.push(availableTime.startTime + " - " + availableTime.endTime)
            } 
        })
        if(availability.morning.length === 0){
          availability.morning.push("N/A")
        }
        if(availability.afternoon.length === 0){
          availability.afternoon.push("N/A")
        }
      })
    },
    confirmDeleteRecord() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `Confirm Delete`,
        text: `You are about to delete this user.`,
        accept: this.deleteDetailHandler,
        acceptText: "Delete"
      })
    },
    deleteDetailHandler() {
      let tutorId = this.$route.params.adminId
      // let tutorData = {deletedAt : new Date()}
      this.deleteUserById({tutorId}).then(res => {
        this.$vs.notify({
          title: 'Delete',
          text: 'User deleted successfully',
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'success'
        })
        this.$router.replace({name:'Admins'})
      }).catch(err => {
        this.$vs.notify({
          title: 'Failure',
          text: 'Something went wrong.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    }
  },
  async mounted() {
      await this.getTutorDetails()
  }
}

</script>

<style lang="scss">
#avatar-col {
  width: 10rem;
}

#page-user-view {
  table {
    td {
      vertical-align: top;
      min-width: 140px;
      padding-bottom: .8rem;
      word-break: break-all;
    }

    &:not(.permissions-table) {
      td {
        @media screen and (max-width:370px) {
          display: block;
        }
      }
    }
  }
}

// #account-info-col-1 {
//   // flex-grow: 1;
//   width: 30rem !important;
//   @media screen and (min-width:1200px) {
//     & {
//       flex-grow: unset !important;
//     }
//   }
// }


@media screen and (min-width:1201px) and (max-width:1211px),
only screen and (min-width:636px) and (max-width:991px) {
  #account-info-col-1 {
    width: calc(100% - 12rem) !important;
  }

  // #account-manage-buttons {
  //   width: 12rem !important;
  //   flex-direction: column;

  //   > button {
  //     margin-right: 0 !important;
  //     margin-bottom: 1rem;
  //   }
  // }

}

  .imgfit {
    height : 110px;
    width: 110px;
    border-radius: 50%;
    margin-left: 5px;
    margin-right: 10px;
    object-fit: cover;
  }
</style>
